@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {

	/* Extra Light */

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 200;
		font-style: normal;
		src: url('./fonts/jakarta/PlusJakartaSans-ExtraLight.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-ExtraLight.woff') format('woff');
	
	}

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 200;
		font-style: italic;
		src: url('./fonts/jakarta/PlusJakartaSans-ExtraLightItalic.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-ExtraLightItalic.woff') format('woff');
	
	}
	
	/* Light */

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 300;
		font-style: normal;
		src: url('./fonts/jakarta/PlusJakartaSans-Light.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-Light.woff') format('woff');
	
	}

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 300;
		font-style: italic;
		src: url('./fonts/jakarta/PlusJakartaSans-LightItalic.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-LightItalic.woff') format('woff');
	
	}
	
	/* Normal */

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 400;
		font-style: normal;
		src: url('./fonts/jakarta/PlusJakartaSans-Regular.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-Regular.woff') format('woff');
	
	}

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 400;
		font-style: italic;
		src: url('./fonts/jakarta/PlusJakartaSans-Italic.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-Italic.woff') format('woff');
	
	}
	
	/* Medium */

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 500;
		font-style: normal;
		src: url('./fonts/jakarta/PlusJakartaSans-Medium.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-Medium.woff') format('woff');
	
	}

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 500;
		font-style: italic;
		src: url('./fonts/jakarta/PlusJakartaSans-MediumItalic.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-MediumItalic.woff') format('woff');
	
	}
	
	/* Semi Bold */

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 600;
		font-style: normal;
		src: url('./fonts/jakarta/PlusJakartaSans-SemiBold.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-SemiBold.woff') format('woff');
	
	}

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 600;
		font-style: italic;
		src: url('./fonts/jakarta/PlusJakartaSans-SemiBoldItalic.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-SemiBoldItalic.woff') format('woff');
	
	}
	
	/* Bold */

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 700;
		font-style: normal;
		src: url('./fonts/jakarta/PlusJakartaSans-Bold.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-Bold.woff') format('woff');
	
	}

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 700;
		font-style: italic;
		src: url('./fonts/jakarta/PlusJakartaSans-BoldItalic.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-BoldItalic.woff') format('woff');
	
	}
	
	/* Extra Bold */

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 800;
		font-style: normal;
		src: url('./fonts/jakarta/PlusJakartaSans-ExtraBold.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-ExtraBold.woff') format('woff');
	
	}

	@font-face {
	
		font-family: 'jakarta';
		font-weight: 800;
		font-style: italic;
		src: url('./fonts/jakarta/PlusJakartaSans-ExtraBoldItalic.woff2') format('woff2'), url('./fonts/jakarta/PlusJakartaSans-ExtraBoldItalic.woff') format('woff');
	
	}
	
	

}